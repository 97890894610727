import {
  Breadcrumbs,
  Button,
  Divider,
  Typography,
  Link as MUILink,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  ListItem,
  List,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import { Add, Delete, FactCheck, Save } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import {
  useState,
  ChangeEvent,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import pageStyle from "../pages/ProposalDetails.module.css";
import { formatMoney } from "../utils";
import Customer from "../model/customer";
import { database } from "..";

import { default as ProposalLineItemType } from "../model/proposalLineItem";
import Product from "../model/product";
import Proposal from "../model/proposal";

const ProposalLineItem = forwardRef(function ProposalLineItem(
  props: {
    setIsModified: (v?: boolean) => void;
    removeLine: () => void;
    id?: string;
  },
  ref
) {
  const { setIsModified, removeLine, id } = props;

  const [productId, setProductId] = useState("");
  const [notes, setNotes] = useState("");
  const [price, setPrice] = useState<any>("");
  const [quantity, setQuantity] = useState<any>("");

  const [productsData, setProductsData] = useState<Product[]>([]);

  useEffect(() => {
    (async () => {
      const productCollection = database.get<Product>("products");
      let query = productCollection.query();
      const products: Product[] = await query.fetch();
      setProductsData(products);
    })();
  }, []);

  // Handle changes
  const changeProductId = async (change: SelectChangeEvent<string>) => {
    setIsModified(true);
    setProductId(change.target.value);
    const productInfo = await database
      .get<Product>("products")
      .find(change.target.value);
    setPrice(productInfo.price);
    setNotes(productInfo.description || "");
  };
  const changeNotes = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setNotes(change.target.value);
  };
  const changeQuantity = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setQuantity(change.target.value);
  };
  const changePrice = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setPrice(change.target.value);
  };

  const deleteLine = async () => {
    if (id) {
      await database.write(async () => {
        const line = await database
          .get<ProposalLineItemType>("proposal_line_items")
          .find(id);
        await line.markAsDeleted();
      });
    }
    removeLine();
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        async save(proposal: Proposal) {
          const productInfo = await database
            .get<Product>("products")
            .find(productId);
          if (!id) {
            await database.write(async () => {
              const newProposalLine = await database
                .get<ProposalLineItemType>("proposal_line_items")
                .create((pl) => {
                  const p: any = pl.product;
                  p.set(productInfo);
                  const pr: any = pl.proposal;
                  pr.set(proposal);
                  pl.productName = productInfo?.title;
                  pl.productNotes = notes;
                  pl.unitPrice = Number(price);
                  pl.quantity = Number(quantity);
                });
            });
          } else {
            await database.write(async () => {
              const proposalLineItems = await database
                .get<ProposalLineItemType>("proposal_line_items")
                .find(id);
              await proposalLineItems.update((pl) => {
                const p: any = pl.product;

                p.set(productInfo);
                const pr: any = pl.proposal;
                pr.set(proposal);
                pl.productName = productInfo?.title;
                pl.productNotes = notes;
                pl.unitPrice = Number(price);
                pl.quantity = Number(quantity);
              });
            });
          }
        },
        subtotalAmount(): number {
          return Number(quantity) * Number(price);
        },
        async taxAmount(rate: number) {
          if (!productId) return 0.0;
          const productInfo = await database
            .get<Product>("products")
            .find(productId);
          if (productInfo?.taxable) {
            return this.subtotalAmount() * (rate / 100);
          }
          return 0.0;
        },
      };
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      const line = await database
        .get<ProposalLineItemType>("proposal_line_items")
        .find(id);
      if (line.product) {
        setProductId(line.product.id);
        setIsModified();
      }
      if (line.productNotes) setNotes(line.productNotes);
      if (line.quantity) setQuantity(line.quantity);
      if (line.unitPrice) setPrice(line.unitPrice);
    })();
  }, [id]);

  return (
    <ListItem>
      <div className={pageStyle.proposalLineItem}>
        <div className={pageStyle.editProposalForm}>
          <FormControl>
            <InputLabel>Product</InputLabel>
            <Select
              onChange={changeProductId}
              value={productId}
              label="Product"
            >
              {productsData.map((p) => (
                <MenuItem value={p.id}>{p.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="notesInput">Notes</InputLabel>
            <OutlinedInput
              value={notes}
              onChange={changeNotes}
              multiline
              minRows={3}
              id="notesInput"
              label="Notes"
            />
          </FormControl>
        </div>
        <div className={pageStyle.proposalLineItemModifiers}>
          <FormControl>
            <InputLabel>Quantity</InputLabel>
            <OutlinedInput
              type="number"
              value={quantity}
              onChange={changeQuantity}
              label="Quantity"
            />
          </FormControl>
          <FormControl>
            <InputLabel>Price</InputLabel>
            <OutlinedInput
              type="number"
              value={price}
              onChange={changePrice}
              label="Price"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
          <IconButton
            className={pageStyle.deleteLineButton}
            onClick={deleteLine}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
    </ListItem>
  );
});

export default ProposalLineItem;
