import {
  Breadcrumbs,
  Button,
  Divider,
  Typography,
  Link as MUILink,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  ListItem,
  List,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import { Add, Delete, FactCheck, Save } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import { useState, ChangeEvent, useEffect, useRef } from "react";
import pageStyle from "./ProposalDetails.module.css";
import { formatMoney } from "../utils";
import Customer from "../model/customer";
import { database } from "..";
import ProposalLineItem from "../components/ProposalLineItem";
import { default as ProposalLineItemType } from "../model/proposalLineItem";
import Tax from "../model/tax";
import Proposal from "../model/proposal";
import { Q } from "@nozbe/watermelondb";

function ProposalDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const cid = searchParams.get("cid");

  const [customersData, setCustomersData] = useState<Customer[]>([]);

  useEffect(() => {
    (async () => {
      const customerCollection = database.get<Customer>("customers");
      let query = customerCollection.query();
      const customers: Customer[] = await query.fetch();
      setCustomersData(customers);
    })();
  }, []);

  const [customerData, setCustomerData] = useState<Customer | null>(null);

  const [lineItems, setLineItems] = useState<{ id?: string; key: string }[]>(
    []
  );
  const lineItemsRef = useRef<Array<typeof ProposalLineItem>>([]);
  useEffect(() => {
    lineItemsRef.current = lineItemsRef.current.slice(0, lineItems.length);
  }, [lineItems]);

  const newLine = () => {
    setLineItems([...lineItems, { key: Date.now().toString() }]);
  };

  const removeLine = (i: number) => () => {
    lineItems.splice(i, 1);
    setLineItems([...lineItems]);
  };

  const [isModified, _setIsModified] = useState(false);
  const [forceModified, setForceModified] = useState(0);
  const setIsModified = (update?: boolean) => {
    setForceModified(forceModified + 1);
    if (update !== undefined) _setIsModified(update);
  };

  // Form fields
  const [customerId, setCustomerId] = useState(cid ? cid : "");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("NY");
  const [zip, setZip] = useState("");
  const [notes, setNotes] = useState("");

  const [status, setStatus] = useState("Quoted");

  // Handle changes
  const changeCustomer = (change: SelectChangeEvent<string>) => {
    setIsModified(true);
    setCustomerId(change.target.value);
  };
  const changeAddressLine1 = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setAddressLine1(change.target.value);
  };
  const changeAddressLine2 = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setAddressLine2(change.target.value);
  };
  const changeCity = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setCity(change.target.value);
  };
  const changeState = (change: SelectChangeEvent<string>) => {
    setIsModified(true);
    setState(change.target.value);
  };
  const changeZip = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setZip(change.target.value);
  };
  const changeNotes = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setNotes(change.target.value);
  };

  const [taxId, setTaxId] = useState("");
  const changeTaxId = (change: SelectChangeEvent<string>) => {
    setIsModified(true);
    setTaxId(change.target.value);
  };

  const [taxInfo, setTaxInfo] = useState<Tax | null>(null);

  const [subtotalAmount, setSubtotalAmount] = useState<any>(0);
  const [taxAmount, setTaxAmount] = useState<any>(0);
  const [totalAmount, setTotalAmount] = useState<any>(0);

  const canSave = () => {
    // TODO: Add validation
    return isModified;
  };

  useEffect(() => {
    (async () => {
      if (!customerId) {
        return;
      }
      const customer = await database
        .get<Customer>("customers")
        .find(customerId);
      setCustomerData(customer);
    })();
  }, [customerId]);

  const save = async () => {
    let savingProposal: Proposal | null = null;
    if (!id) {
      await database.write(async () => {
        const newProposal = await database
          .get<Proposal>("proposals")
          .create((p) => {
            const c: any = p.customer;
            c.set(customerData);
            p.firstName = customerData?.firstName;
            p.lastName = customerData?.lastName;
            p.phone = customerData?.phone;
            p.phoneExt = customerData?.phoneExt;
            p.email = customerData?.email;
            p.addressLine1 = customerData?.addressLine1;
            p.addressLine2 = customerData?.addressLine2;
            p.city = customerData?.city;
            p.state = customerData?.state;
            p.zip = customerData?.zip;
            p.serviceAddressLine1 = addressLine1;
            p.serviceAddressLine2 = addressLine2;
            p.serviceCity = city;
            p.serviceState = state;
            p.serviceZip = zip;
            p.serviceNotes = notes;

            p.status = status;

            const t: any = p.tax;
            t.set(taxInfo);
            p.taxName = taxInfo?.title;
            p.taxRate = taxInfo?.percentRate;

            p.subtotalAmount = Number(subtotalAmount);
            p.taxAmount = Number(taxAmount);
            p.totalAmount = Number(totalAmount);
          });
        setSearchParams({ id: newProposal.id });
        savingProposal = newProposal;
      });
    } else {
      await database.write(async () => {
        const proposal = await database.get<Proposal>("proposals").find(id);
        await proposal.update((p) => {
          const c: any = p.customer;
          c.set(customerData);
          p.firstName = customerData?.firstName;
          p.lastName = customerData?.lastName;
          p.phone = customerData?.phone;
          p.phoneExt = customerData?.phoneExt;
          p.email = customerData?.email;
          p.addressLine1 = customerData?.addressLine1;
          p.addressLine2 = customerData?.addressLine2;
          p.city = customerData?.city;
          p.state = customerData?.state;
          p.zip = customerData?.zip;
          p.serviceAddressLine1 = addressLine1;
          p.serviceAddressLine2 = addressLine2;
          p.serviceCity = city;
          p.serviceState = state;
          p.serviceZip = zip;
          p.serviceNotes = notes;

          p.status = status;

          const t: any = p.tax;
          t.set(taxInfo);
          p.taxName = taxInfo?.title;
          p.taxRate = taxInfo?.percentRate;

          p.subtotalAmount = Number(subtotalAmount);
          p.taxAmount = Number(taxAmount);
          p.totalAmount = Number(totalAmount);
        });
        savingProposal = proposal;
      });
    }
    setIsModified(false);
    await Promise.all(
      lineItemsRef.current.map((v: any) => v.save(savingProposal))
    );
  };

  const [taxesData, setTaxesData] = useState<Tax[]>([]);
  useEffect(() => {
    (async () => {
      if (!taxId) {
        return;
      }
      const tax = await database.get<Tax>("taxes").find(taxId);
      setTaxInfo(tax);
    })();
  }, [taxId]);

  useEffect(() => {
    (async () => {
      const taxCollection = database.get<Tax>("taxes");
      let query = taxCollection.query();
      const taxes: Tax[] = await query.fetch();
      setTaxesData(taxes);
    })();
  }, []);

  useEffect(() => {
    if (!taxInfo) {
      setTaxAmount((0).toFixed(2));
      return;
    }
    (async () => {
      setTaxAmount(
        (
          await Promise.all(
            lineItemsRef.current.map((v: any) =>
              v.taxAmount(taxInfo?.percentRate)
            )
          )
        )
          .reduce((sum, cur) => sum + cur, 0)
          .toFixed(2)
      );
    })();
  }, [forceModified, taxInfo]);

  useEffect(() => {
    (async () => {
      setSubtotalAmount(
        (
          await Promise.all(
            lineItemsRef.current.map((v: any) => v.subtotalAmount())
          )
        )
          .reduce((sum, cur) => sum + cur, 0)
          .toFixed(2)
      );
    })();
  }, [forceModified]);

  useEffect(() => {
    setTotalAmount((Number(subtotalAmount) + Number(taxAmount)).toFixed(2));
  }, [subtotalAmount, taxAmount]);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      const proposal = await database.get<Proposal>("proposals").find(id);
      if (proposal.customer) {
        setCustomerData(proposal.customer);
        setCustomerId(proposal.customer.id);
      }
      if (proposal.serviceAddressLine1)
        setAddressLine1(proposal.serviceAddressLine1);
      if (proposal.serviceAddressLine2)
        setAddressLine2(proposal.serviceAddressLine2);
      if (proposal.serviceCity) setCity(proposal.serviceCity);
      if (proposal.serviceState) setState(proposal.serviceState);
      if (proposal.serviceZip) setZip(proposal.serviceZip);
      if (proposal.serviceNotes) setNotes(proposal.serviceNotes);

      if (proposal.status) setStatus(proposal.status);

      if (proposal.tax) {
        setTaxId(proposal.tax.id);
      }
      if (proposal.subtotalAmount) setSubtotalAmount(proposal.subtotalAmount);
      if (proposal.taxAmount) setTaxAmount(proposal.taxAmount);
      if (proposal.totalAmount) setTotalAmount(proposal.totalAmount);
      const proposalLines = await database
        .get<ProposalLineItemType>("proposal_line_items")
        .query(Q.where("proposal_id", id))
        .fetch();
      setLineItems(proposalLines.map((pl) => ({ key: pl.id, id: pl.id })));
    })();
  }, [id]);

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <Breadcrumbs>
            <MUILink
              component={Link}
              underline="hover"
              color="inherit"
              to="/proposals"
            >
              Proposals
            </MUILink>
            <Typography color="text.primary">Details</Typography>
          </Breadcrumbs>
          <div className={pageStyle.proposalDetailContainer}>
            <div className={pageStyle.proposalDetailCustomerContainer}>
              <FormControl>
                <InputLabel>Customer</InputLabel>
                <Select
                  value={customerId}
                  onChange={changeCustomer}
                  label="Customer"
                >
                  {customersData.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.firstName} {c.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider />
              {customerData && (
                <>
                  <Typography>{customerData.addressLine1}</Typography>
                  <Typography>{customerData.addressLine2}</Typography>
                  <Typography>
                    {customerData.city}, {customerData.state} {customerData.zip}
                  </Typography>
                  <Divider />
                  <Typography>
                    {customerData.phone}
                    {customerData.phoneExt ? `x${customerData.phoneExt}` : ""}
                  </Typography>
                  <Typography>{customerData.addressLine2}</Typography>
                </>
              )}
            </div>
            <div>
              <form
                className={pageStyle.editProposalForm}
                noValidate
                autoComplete="off"
              >
                <FormControl>
                  <InputLabel htmlFor="addressLine1Input">
                    Address Line 1
                  </InputLabel>
                  <OutlinedInput
                    value={addressLine1}
                    onChange={changeAddressLine1}
                    id="addressLine1Input"
                    label="Address Line 1"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="addressLine2Input">
                    Address Line 2
                  </InputLabel>
                  <OutlinedInput
                    value={addressLine2}
                    onChange={changeAddressLine2}
                    id="addressLine2Input"
                    label="Address Line 2"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="cityInput">City</InputLabel>
                  <OutlinedInput
                    value={city}
                    onChange={changeCity}
                    id="cityInput"
                    label="City"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="stateInput">State</InputLabel>
                  <Select
                    id="stateInput"
                    label="State"
                    value={state}
                    onChange={changeState}
                  >
                    <MenuItem value={"NY"}>New York</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="zipInput">Zip Code</InputLabel>
                  <OutlinedInput
                    value={zip}
                    onChange={changeZip}
                    id="zipInput"
                    label="Zip Code"
                  />
                </FormControl>
                <Divider />
                <FormControl>
                  <InputLabel htmlFor="notesInput">Notes</InputLabel>
                  <OutlinedInput
                    value={notes}
                    onChange={changeNotes}
                    multiline
                    minRows={3}
                    id="notesInput"
                    label="Notes"
                  />
                </FormControl>
              </form>
            </div>
            <div className={pageStyle.proposalDetailLineItems}>
              <List>
                {lineItems.map((line, i) => {
                  return (
                    <ProposalLineItem
                      ref={(el: typeof ProposalLineItem) =>
                        (lineItemsRef.current[i] = el)
                      }
                      removeLine={removeLine(i)}
                      setIsModified={setIsModified}
                      id={line.id}
                      key={line.key}
                    />
                  );
                })}
              </List>
              <Button
                color="success"
                variant="outlined"
                startIcon={<Add />}
                onClick={newLine}
              >
                Add Line
              </Button>
            </div>
            <div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Subtotal</TableCell>
                    <TableCell>{formatMoney(subtotalAmount)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "100%" }}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel>Tax</InputLabel>
                        <Select
                          onChange={changeTaxId}
                          value={taxId}
                          label="Tax"
                          sx={{ width: "100%" }}
                        >
                          {taxesData.map((t) => (
                            <MenuItem key={t.id} value={t.id}>
                              {t.title} ({t.percentRate}%)
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>{formatMoney(taxAmount)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>{formatMoney(totalAmount)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.controlHeaderButtonContainer}>
              <Button
                color="success"
                variant="contained"
                startIcon={<Save />}
                disabled={!canSave()}
                onClick={save}
              >
                Save
              </Button>
              <Button
                variant="contained"
                startIcon={<FactCheck />}
                onClick={() => {}}
              >
                Contract
              </Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ProposalDetailsPage;
