import {
  Breadcrumbs,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Link as MUILink,
  InputAdornment,
  FormControlLabel,
  Switch,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import pageStyle from "./CustomerDetails.module.css";
import { Delete, Save } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, ChangeEvent, useEffect } from "react";
import { database } from "..";
import Tax from "../model/tax";
import Product from "../model/product";

function ProductDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      const product = await database.get<Product>("products").find(id);
      if (product.title) setTitle(product.title);
      if (product.description) setDescription(product.description);
      if (product.price) setPrice(product.price);
      if (product.taxable) setTaxable(product.taxable);
    })();
  }, [id]);

  const [isModified, setIsModified] = useState(false);

  // Form fields
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [taxable, setTaxable] = useState(true);

  // Handle changes
  const changeTitle = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setTitle(change.target.value);
  };
  const changeDescription = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setDescription(change.target.value);
  };
  const changePrice = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setPrice(Number(change.target.value));
  };
  const changeTaxable = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setTaxable(!!change.target.checked);
  };

  const canSave = () => {
    // TODO: Add validation
    return isModified;
  };

  const handleSave = async () => {
    // handle create case
    if (!id) {
      await database.write(async () => {
        const newProduct = await database
          .get<Product>("products")
          .create((p) => {
            p.title = title;
            p.description = description;
            p.price = price;
            p.taxable = taxable;
          });
        setSearchParams({ id: newProduct.id });
      });
    } else {
      await database.write(async () => {
        const product = await database.get<Product>("products").find(id);
        await product.update((p) => {
          p.title = title;
          p.description = description;
          p.price = price;
          p.taxable = taxable;
        });
      });
    }
    setIsModified(false);
  };

  const handleDelete = async () => {
    if (id) {
      await database.write(async () => {
        const tax = await database.get<Product>("products").find(id);
        await tax.markAsDeleted();
      });
    }
    navigate("/products");
  };

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <Breadcrumbs>
            <MUILink
              component={Link}
              underline="hover"
              color="inherit"
              to="/products"
            >
              Products
            </MUILink>
            <Typography color="text.primary">Details</Typography>
          </Breadcrumbs>

          <div className={pageStyle.customerDetailPanel}>
            <div>
              <form
                className={pageStyle.editCustomerForm}
                noValidate
                autoComplete="off"
              >
                <FormControl>
                  <InputLabel htmlFor="titleInput">Title</InputLabel>
                  <OutlinedInput
                    value={title}
                    onChange={changeTitle}
                    id="titleInput"
                    label="Title"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="descriptionInput">
                    Description
                  </InputLabel>
                  <OutlinedInput
                    value={description}
                    onChange={changeDescription}
                    multiline
                    minRows={3}
                    id="descriptionInput"
                    label="Description"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel>Price</InputLabel>
                  <OutlinedInput
                    type="number"
                    value={price}
                    onChange={changePrice}
                    label="Price"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch checked={taxable} onChange={changeTaxable} />
                  }
                  label="Taxable"
                />
              </form>
              <div className={utilStyle.controlHeader}>
                <div className={utilStyle.controlHeaderButtonContainer}>
                  <Button
                    color="success"
                    variant="contained"
                    startIcon={<Save />}
                    disabled={!canSave()}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    color="error"
                    variant="outlined"
                    startIcon={<Delete />}
                    disabled={!id}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ProductDetailsPage;
