import {
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
  Link as MUILink,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import pageStyle from "./CustomerDetails.module.css";
import {
  AccessTime,
  Add,
  Assignment,
  Delete,
  Photo as MUIPhoto,
  Save,
} from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import { useState, ChangeEvent, useEffect } from "react";
import { database } from "..";
import Customer from "../model/customer";
import Photo from "../model/photo";
import { Q } from "@nozbe/watermelondb";
import Proposal from "../model/proposal";
import { formatMoney } from "../utils";

function CustomerDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      const customer = await database.get<Customer>("customers").find(id);
      if (customer.firstName) setFirstName(customer.firstName);
      if (customer.lastName) setLastName(customer.lastName);
      if (customer.phone) setPhone(customer.phone);
      if (customer.phoneExt) setPhoneExt(customer.phoneExt);
      if (customer.email) setEmail(customer.email);
      if (customer.addressLine1) setAddressLine1(customer.addressLine1);
      if (customer.addressLine2) setAddressLine2(customer.addressLine2);
      if (customer.city) setCity(customer.city);
      if (customer.state) setState(customer.state);
      if (customer.zip) setZip(customer.zip);
      if (customer.notes) setNotes(customer.notes);
      const photos = (
        await database
          .get<Photo>("photos")
          .query(Q.where("customer_id", id))
          .fetch()
      ).map((p) => ({
        type: "photo",
        id: p.id,
        photoData: p.photoData,
        createdAt: p.createdAt,
      }));
      const proposals = (
        await database
          .get<Proposal>("proposals")
          .query(Q.where("customer_id", id))
          .fetch()
      ).map((p) => ({
        type: "proposal",
        id: p.id,
        createdAt: p.createdAt,
        totalAmount: p.totalAmount,
        status: p.status,
      }));
      setCustomerActivity(
        [...photos, ...proposals].sort(
          (a, b) => (a.createdAt || 0) - (b.createdAt || 0)
        )
      );
    })();
  }, [id]);

  const [customerActivity, setCustomerActivity] = useState<any[]>([]);

  // Dropdown menus
  const [newActivityAnchorEl, setNewActivityAnchorEl] =
    useState<null | HTMLElement>(null);
  const newActivityOpen = !!newActivityAnchorEl;

  const [isModified, setIsModified] = useState(false);

  // Form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneExt, setPhoneExt] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("NY");
  const [zip, setZip] = useState("");
  const [notes, setNotes] = useState("");

  // Handle changes
  const changeFirstName = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setFirstName(change.target.value);
  };
  const changeLastName = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setLastName(change.target.value);
  };
  const changePhone = (change: string) => {
    setIsModified(true);
    setPhone(change);
  };
  const changePhoneExt = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setPhoneExt(change.target.value);
  };
  const changeEmail = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setEmail(change.target.value);
  };
  const changeAddressLine1 = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setAddressLine1(change.target.value);
  };
  const changeAddressLine2 = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setAddressLine2(change.target.value);
  };
  const changeCity = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setCity(change.target.value);
  };
  const changeState = (change: SelectChangeEvent<string>) => {
    setIsModified(true);
    setState(change.target.value);
  };
  const changeZip = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setZip(change.target.value);
  };
  const changeNotes = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setNotes(change.target.value);
  };

  const canSave = () => {
    // TODO: Add validation
    return isModified;
  };

  const handleSave = async () => {
    // handle create case
    if (!id) {
      await database.write(async () => {
        const newCustomer = await database
          .get<Customer>("customers")
          .create((c) => {
            c.firstName = firstName;
            c.lastName = lastName;
            c.phone = phone;
            c.phoneExt = phoneExt;
            c.email = email;
            c.addressLine1 = addressLine1;
            c.addressLine2 = addressLine2;
            c.city = city;
            c.state = state;
            c.zip = zip;
            c.notes = notes;
          });
        setSearchParams({ id: newCustomer.id });
      });
    } else {
      await database.write(async () => {
        const customer = await database.get<Customer>("customers").find(id);
        await customer.update((c) => {
          c.firstName = firstName;
          c.lastName = lastName;
          c.phone = phone;
          c.phoneExt = phoneExt;
          c.email = email;
          c.addressLine1 = addressLine1;
          c.addressLine2 = addressLine2;
          c.city = city;
          c.state = state;
          c.zip = zip;
          c.notes = notes;
        });
      });
    }
    setIsModified(false);
  };

  const handleDelete = async () => {
    if (id) {
      await database.write(async () => {
        const customer = await database.get<Customer>("customers").find(id);
        await customer.markAsDeleted();
      });
    }
    navigate("/customers");
  };

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <Breadcrumbs>
            <MUILink
              component={Link}
              underline="hover"
              color="inherit"
              to="/customers"
            >
              Customers
            </MUILink>
            <Typography color="text.primary">Details</Typography>
          </Breadcrumbs>

          <div className={pageStyle.customerDetailContainer}>
            <div className={pageStyle.customerDetailPanel}>
              <div>
                <form
                  className={pageStyle.editCustomerForm}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl>
                    <InputLabel htmlFor="firstNameInput">First Name</InputLabel>
                    <OutlinedInput
                      value={firstName}
                      onChange={changeFirstName}
                      id="firstNameInput"
                      label="First Name"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="lastNameInput">Last Name</InputLabel>
                    <OutlinedInput
                      value={lastName}
                      onChange={changeLastName}
                      id="lastNameInput"
                      label="Last Name"
                    />
                  </FormControl>
                  <Divider />
                  <FormControl>
                    <MuiTelInput
                      defaultCountry="US"
                      value={phone}
                      onChange={changePhone}
                      id="PhoneNumberInput"
                      label="Phone Number"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="phoneExtInput">
                      Phone Extension
                    </InputLabel>
                    <OutlinedInput
                      value={phoneExt}
                      onChange={changePhoneExt}
                      id="phoneExtInput"
                      label="Phone Extension"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="emailInput">Email</InputLabel>
                    <OutlinedInput
                      value={email}
                      onChange={changeEmail}
                      id="emailInput"
                      label="Email"
                    />
                  </FormControl>
                  <Divider />
                  <FormControl>
                    <InputLabel htmlFor="addressLine1Input">
                      Address Line 1
                    </InputLabel>
                    <OutlinedInput
                      value={addressLine1}
                      onChange={changeAddressLine1}
                      id="addressLine1Input"
                      label="Address Line 1"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="addressLine2Input">
                      Address Line 2
                    </InputLabel>
                    <OutlinedInput
                      value={addressLine2}
                      onChange={changeAddressLine2}
                      id="addressLine2Input"
                      label="Address Line 2"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="cityInput">City</InputLabel>
                    <OutlinedInput
                      value={city}
                      onChange={changeCity}
                      id="cityInput"
                      label="City"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="stateInput">State</InputLabel>
                    <Select
                      id="stateInput"
                      label="State"
                      value={state}
                      onChange={changeState}
                    >
                      <MenuItem value={"NY"}>New York</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="zipInput">Zip Code</InputLabel>
                    <OutlinedInput
                      value={zip}
                      onChange={changeZip}
                      id="zipInput"
                      label="Zip Code"
                    />
                  </FormControl>
                  <Divider />
                  <FormControl>
                    <InputLabel htmlFor="notesInput">Notes</InputLabel>
                    <OutlinedInput
                      value={notes}
                      onChange={changeNotes}
                      multiline
                      minRows={3}
                      id="notesInput"
                      label="Notes"
                    />
                  </FormControl>
                </form>
                <div className={utilStyle.controlHeader}>
                  <div className={utilStyle.controlHeaderButtonContainer}>
                    <Button
                      color="success"
                      variant="contained"
                      startIcon={<Save />}
                      disabled={!canSave()}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    <Button
                      color="error"
                      variant="outlined"
                      startIcon={<Delete />}
                      disabled={!id}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Divider orientation="vertical" />
              <Divider />
            </div>

            <div className={pageStyle.customerActivityPanel}>
              <List>
                {customerActivity.map((activity) => {
                  switch (activity.type) {
                    case "proposal":
                      return (
                        <ListItemButton
                          key={activity.id}
                          component={Link}
                          to={`/proposals/details?id=${activity.id}`}
                        >
                          <ListItemIcon>
                            <Assignment />
                          </ListItemIcon>
                          <ListItemText
                            primary="Proposal"
                            secondary={formatMoney(activity.totalAmount)}
                          />
                          <Chip
                            label={activity.status}
                            color="primary"
                            variant="filled"
                          />
                        </ListItemButton>
                      );
                    case "salesCall":
                      return (
                        <ListItemButton key={activity.id}>
                          <ListItemIcon>
                            <AccessTime />
                          </ListItemIcon>
                          <ListItemText
                            primary="Sales Call"
                            secondary={`Thursday, June 6, 2024, 11:00 AM`}
                          />
                        </ListItemButton>
                      );
                    case "photo":
                      return (
                        <ListItemButton
                          component={Link}
                          to={`/photos/details?id=${activity.id}&cid=${id}`}
                          key={activity.id}
                        >
                          <ListItemIcon>
                            <MUIPhoto />
                          </ListItemIcon>
                          <img
                            className={pageStyle.customerPhoto}
                            src={activity.photoData}
                            style={{ maxWidth: "400px" }}
                          />
                        </ListItemButton>
                      );
                  }
                })}
              </List>
              <div className={utilStyle.controlHeader}>
                <div className={utilStyle.controlHeaderButtonContainer}>
                  <Button
                    onClick={(e) => setNewActivityAnchorEl(e.currentTarget)}
                    variant="contained"
                    startIcon={<Add />}
                    disabled={!id}
                  >
                    New
                  </Button>

                  <Menu
                    anchorEl={newActivityAnchorEl}
                    open={newActivityOpen}
                    onClose={() => setNewActivityAnchorEl(null)}
                    sx={{ width: 320, maxWidth: "100%" }}
                  >
                    <MenuList>
                      <MenuItem
                        component={Link}
                        to={`/proposals/details?cid=${id}`}
                      >
                        <ListItemIcon>
                          <Assignment fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Proposal</ListItemText>
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <AccessTime fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Sales Call</ListItemText>
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={`/photos/details?cid=${id}`}
                      >
                        <ListItemIcon>
                          <MUIPhoto fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Photo</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default CustomerDetailsPage;
