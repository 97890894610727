import { FormEvent, useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link, useSearchParams } from "react-router-dom";
import {
  List,
  Avatar,
  ListItemText,
  ListItemAvatar,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Button,
  ListItemButton,
  Menu,
  ListItem,
} from "@mui/material";
import { Add, Search, Download, FilterList } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { database } from "..";
import Customer from "../model/customer";
import dayjs from "dayjs";
import { Q } from "@nozbe/watermelondb";

function CustomerListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get("q");

  const [customersData, setCustomerData] = useState<Customer[]>([]);

  // filters
  const [createdStart, setCreatedStart] = useState<number | undefined>(
    undefined
  );
  const [createdEnd, setCreatedEnd] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState(q || "");

  useEffect(() => {
    (async () => {
      const customerCollection = database.get<Customer>("customers");
      let query = customerCollection.query();
      if (createdStart) {
        query = query.extend(Q.where("created_at", Q.gte(createdStart)));
      }
      if (createdEnd) {
        query = query.extend(
          Q.where("created_at", Q.lte(createdEnd + 86400 * 1000))
        );
      }
      if (q) {
        query = query.extend(
          Q.or(
            Q.where("first_name", Q.like(`%${Q.sanitizeLikeString(q)}%`)),
            Q.where("last_name", Q.like(`%${Q.sanitizeLikeString(q)}%`))
          )
        );
      }
      const customers: Customer[] = await query.fetch();
      setCustomerData(customers);
    })();
  }, [createdStart, createdEnd, q]);

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchParams((prev) => {
      prev.set("q", search);
      return prev;
    });
  };

  // Dropdown menus
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const filterOpen = !!filterAnchorEl;

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.searchBar}>
              <form onSubmit={handleSearchSubmit}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="searchInput">Search</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="searchInput"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    label="Search"
                    endAdornment={
                      <IconButton type="submit">
                        <Search />
                      </IconButton>
                    }
                  />
                </FormControl>
              </form>
            </div>
            <div className={utilStyle.controlHeaderButtonContainer}>
              <Button
                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                variant="outlined"
                startIcon={<FilterList />}
              >
                Filter
              </Button>
              <Menu
                anchorEl={filterAnchorEl}
                open={filterOpen}
                onClose={() => setFilterAnchorEl(null)}
                sx={{ width: 400, maxWidth: "100%" }}
              >
                <List sx={{ width: 400, maxWidth: "100%" }}>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>Created</ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={(e) =>
                              setCreatedStart(e ? e.unix() * 1000 : undefined)
                            }
                            value={
                              createdStart ? dayjs(createdStart) : undefined
                            }
                            label="Start Date"
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker
                            onChange={(e) =>
                              setCreatedEnd(e ? e.unix() * 1000 : undefined)
                            }
                            value={createdEnd ? dayjs(createdEnd) : undefined}
                            label="End Date"
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>
                      Last Activity
                    </ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker label="Start Date" />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker label="End Date" />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                </List>
              </Menu>
              {/* <Button variant="outlined" startIcon={<Download />}>
                Export
              </Button> */}
              <Button
                component={Link}
                to="/customers/details"
                color="success"
                variant="contained"
                startIcon={<Add />}
              >
                New Customer
              </Button>
            </div>
          </div>
          <List>
            {customersData.map((customer) => (
              <ListItemButton
                component={Link}
                to={`/customers/details?id=${customer.id}`}
                key={customer.id}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#606060" }}>
                    {customer.firstName && customer.firstName[0].toUpperCase()}
                    {customer.lastName && customer.lastName[0].toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${customer.firstName} ${customer.lastName}`}
                  secondary={`${customer.addressLine1} ${customer.city}${
                    customer.city && customer.state && ", "
                  }${customer.state}`}
                />
              </ListItemButton>
            ))}
          </List>
        </main>
      </div>
    </div>
  );
}

export default CustomerListPage;
