import { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link } from "react-router-dom";
import { List, ListItemText, Button, ListItemButton } from "@mui/material";
import { Add, Download } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { database } from "..";
import Product from "../model/product";

function ProductListPage() {
  const [productsData, setProductsData] = useState<Product[]>([]);

  useEffect(() => {
    (async () => {
      const productCollection = database.get<Product>("products");
      let query = productCollection.query();
      const products: Product[] = await query.fetch();
      setProductsData(products);
    })();
  }, []);

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.controlHeaderButtonContainer}>
              {/* <Button variant="outlined" startIcon={<Download />}>
                Export
              </Button> */}
              <Button
                component={Link}
                to="/products/details"
                color="success"
                variant="contained"
                startIcon={<Add />}
              >
                New Product
              </Button>
            </div>
          </div>
          <List>
            {productsData.map((product) => (
              <ListItemButton
                component={Link}
                to={`/products/details?id=${product.id}`}
                key={product.id}
              >
                <ListItemText
                  primary={product.title}
                  secondary={`${product.description}`}
                />
              </ListItemButton>
            ))}
          </List>
        </main>
      </div>
    </div>
  );
}

export default ProductListPage;
