import { FormEvent, useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link, useSearchParams } from "react-router-dom";
import {
  List,
  Avatar,
  ListItemText,
  ListItemAvatar,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Button,
  ListItemButton,
  Menu,
  ListItem,
  Chip,
  ListItemIcon,
} from "@mui/material";
import {
  Add,
  Search,
  Download,
  FilterList,
  AccessTime,
  Assignment,
  Photo as MUIPhoto,
} from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { database } from "..";
import Customer from "../model/customer";
import dayjs from "dayjs";
import { Q } from "@nozbe/watermelondb";
import Photo from "../model/photo";
import Proposal from "../model/proposal";
import { formatMoney } from "../utils";
import pageStyle from "./CustomerDetails.module.css";

function ActivityListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get("q");

  const [customersData, setCustomerData] = useState<Customer[]>([]);

  // filters
  const [createdStart, setCreatedStart] = useState<number | undefined>(
    undefined
  );
  const [createdEnd, setCreatedEnd] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState(q || "");

  useEffect(() => {
    (async () => {
      const customerCollection = database.get<Customer>("customers");
      let query = customerCollection.query();
      if (createdStart) {
        query = query.extend(Q.where("created_at", Q.gte(createdStart)));
      }
      if (createdEnd) {
        query = query.extend(
          Q.where("created_at", Q.lte(createdEnd + 86400 * 1000))
        );
      }
      if (q) {
        query = query.extend(
          Q.or(
            Q.where("first_name", Q.like(`%${Q.sanitizeLikeString(q)}%`)),
            Q.where("last_name", Q.like(`%${Q.sanitizeLikeString(q)}%`))
          )
        );
      }
      const customers: Customer[] = await query.fetch();
      setCustomerData(customers);
    })();
  }, [createdStart, createdEnd, q]);

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchParams((prev) => {
      prev.set("q", search);
      return prev;
    });
  };

  // Dropdown menus
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const filterOpen = !!filterAnchorEl;

  const [activities, setActivities] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const photos = (await database.get<Photo>("photos").query().fetch()).map(
        (p) => ({
          type: "photo",
          id: p.id,
          photoData: p.photoData,
          createdAt: p.createdAt,
        })
      );
      const proposals = (
        await database.get<Proposal>("proposals").query().fetch()
      ).map((p) => ({
        type: "proposal",
        id: p.id,
        createdAt: p.createdAt,
        totalAmount: p.totalAmount,
        status: p.status,
      }));
      setActivities(
        [...photos, ...proposals].sort(
          (a, b) => (a.createdAt || 0) - (b.createdAt || 0)
        )
      );
    })();
  }, []);

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.controlHeaderButtonContainer}>
              <Button
                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                variant="outlined"
                startIcon={<FilterList />}
              >
                Filter
              </Button>
              <Menu
                anchorEl={filterAnchorEl}
                open={filterOpen}
                onClose={() => setFilterAnchorEl(null)}
                sx={{ width: 400, maxWidth: "100%" }}
              >
                <List sx={{ width: 400, maxWidth: "100%" }}>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>Created</ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={(e) =>
                              setCreatedStart(e ? e.unix() * 1000 : undefined)
                            }
                            value={
                              createdStart ? dayjs(createdStart) : undefined
                            }
                            label="Start Date"
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker
                            onChange={(e) =>
                              setCreatedEnd(e ? e.unix() * 1000 : undefined)
                            }
                            value={createdEnd ? dayjs(createdEnd) : undefined}
                            label="End Date"
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>
                      Last Activity
                    </ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker label="Start Date" />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker label="End Date" />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                </List>
              </Menu>
            </div>
          </div>
          <List>
            {activities.map((activity) => {
              switch (activity.type) {
                case "proposal":
                  return (
                    <ListItemButton
                      key={activity.id}
                      component={Link}
                      to={`/proposals/details?id=${activity.id}`}
                    >
                      <ListItemIcon>
                        <Assignment />
                      </ListItemIcon>
                      <ListItemText
                        primary="Proposal"
                        secondary={formatMoney(activity.totalAmount)}
                      />
                      <Chip
                        label={activity.status}
                        color="primary"
                        variant="filled"
                      />
                    </ListItemButton>
                  );
                case "salesCall":
                  return (
                    <ListItemButton key={activity.id}>
                      <ListItemIcon>
                        <AccessTime />
                      </ListItemIcon>
                      <ListItemText
                        primary="Sales Call"
                        secondary={`Thursday, June 6, 2024, 11:00 AM`}
                      />
                    </ListItemButton>
                  );
                case "photo":
                  return (
                    <ListItemButton
                      component={Link}
                      to={`/photos/details?id=${activity.id}`}
                      key={activity.id}
                    >
                      <ListItemIcon>
                        <MUIPhoto />
                      </ListItemIcon>
                      <img
                        className={pageStyle.customerPhoto}
                        src={activity.photoData}
                        style={{ maxWidth: "400px" }}
                      />
                    </ListItemButton>
                  );
              }
            })}
          </List>
        </main>
      </div>
    </div>
  );
}

export default ActivityListPage;
