import { Button, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import TopNav from "../components/TopNav";
import pageStyle from "./CustomerDetails.module.css";
import { Save } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";

function SettingsPage() {
  return (
    <div className="page">
      <TopNav />
      <main>
        <form
          className={pageStyle.editCustomerForm}
          noValidate
          autoComplete="off"
        >
          <FormControl>
            <InputLabel htmlFor="syncUrlInput">Sync URL</InputLabel>
            <OutlinedInput id="syncUrlInput" label="Sync URL" />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="apiKeyInput">API Key</InputLabel>
            <OutlinedInput id="apiKeyInput" label="API Key" />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="termsInput">Terms and Conditions</InputLabel>
            <OutlinedInput
              multiline
              minRows={3}
              id="termsInput"
              label="Terms and Conditions"
            />
          </FormControl>
        </form>
        <div className={utilStyle.controlHeader}>
          <div className={utilStyle.controlHeaderButtonContainer}>
            <Button color="success" variant="contained" startIcon={<Save />}>
              Save
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SettingsPage;
