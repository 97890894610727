import { Model } from "@nozbe/watermelondb";
import {
  text,
  date,
  readonly,
  field,
  relation,
} from "@nozbe/watermelondb/decorators";
import Proposal from "./proposal";
import Product from "./product";

export default class ProposalLineItem extends Model {
  static table = "proposal_line_items";

  @relation("proposals", "proposal_id") proposal?: Proposal;
  @relation("products", "product_id") product?: Product;
  @text("product_name") productName?: string;
  @text("product_notes") productNotes?: string;

  @field("quantity") quantity?: number;
  @field("unit_price") unitPrice?: number;

  @readonly @date("created_at") createdAt?: number;
  @readonly @date("updated_at") updatedAt?: number;
}
