import {
  Breadcrumbs,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Link as MUILink,
  InputAdornment,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import pageStyle from "./CustomerDetails.module.css";
import { Delete, Save } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, ChangeEvent, useEffect } from "react";
import { database } from "..";
import Tax from "../model/tax";

function TaxDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      const tax = await database.get<Tax>("taxes").find(id);
      if (tax.title) setTitle(tax.title);
      if (tax.percentRate) setPercentageRate(tax.percentRate);
    })();
  }, [id]);

  const [isModified, setIsModified] = useState(false);

  // Form fields
  const [title, setTitle] = useState("");
  const [percentRate, setPercentageRate] = useState(0.0);

  // Handle changes
  const changeTitle = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setTitle(change.target.value);
  };
  const changePercentageRate = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setPercentageRate(Number(change.target.value));
  };

  const canSave = () => {
    // TODO: Add validation
    return isModified;
  };

  const handleSave = async () => {
    // handle create case
    if (!id) {
      await database.write(async () => {
        const newTax = await database.get<Tax>("taxes").create((t) => {
          t.title = title;
          t.percentRate = percentRate;
        });
        setSearchParams({ id: newTax.id });
      });
    } else {
      await database.write(async () => {
        const tax = await database.get<Tax>("taxes").find(id);
        await tax.update((t) => {
          t.title = title;
          t.percentRate = percentRate;
        });
      });
    }
    setIsModified(false);
  };

  const handleDelete = async () => {
    if (id) {
      await database.write(async () => {
        const tax = await database.get<Tax>("taxes").find(id);
        await tax.markAsDeleted();
      });
    }
    navigate("/taxes");
  };

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <Breadcrumbs>
            <MUILink
              component={Link}
              underline="hover"
              color="inherit"
              to="/taxes"
            >
              Taxes
            </MUILink>
            <Typography color="text.primary">Details</Typography>
          </Breadcrumbs>

          <div className={pageStyle.customerDetailPanel}>
            <div>
              <form
                className={pageStyle.editCustomerForm}
                noValidate
                autoComplete="off"
              >
                <FormControl>
                  <InputLabel htmlFor="titleInput">Title</InputLabel>
                  <OutlinedInput
                    value={title}
                    onChange={changeTitle}
                    id="titleInput"
                    label="Title"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="percentRateInput">
                    Percent Rate
                  </InputLabel>
                  <OutlinedInput
                    value={percentRate}
                    onChange={changePercentageRate}
                    type="number"
                    id="percentRateInput"
                    label="Percent Rate"
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </FormControl>
              </form>
              <div className={utilStyle.controlHeader}>
                <div className={utilStyle.controlHeaderButtonContainer}>
                  <Button
                    color="success"
                    variant="contained"
                    startIcon={<Save />}
                    disabled={!canSave()}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    color="error"
                    variant="outlined"
                    startIcon={<Delete />}
                    disabled={!id}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default TaxDetailsPage;
