import { Model } from "@nozbe/watermelondb";
import { text, date, readonly, field } from "@nozbe/watermelondb/decorators";

export default class Product extends Model {
  static table = "products";

  @text("title") title?: string;
  @text("description") description?: string;
  @field("price") price?: number;
  @field("taxable") taxable?: boolean;
  @readonly @date("created_at") createdAt?: number;
  @readonly @date("updated_at") updatedAt?: number;
}
