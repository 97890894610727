import { Model } from "@nozbe/watermelondb";
import { text, date, readonly } from "@nozbe/watermelondb/decorators";

export default class Customer extends Model {
  static table = "customers";

  @text("first_name") firstName?: string;
  @text("last_name") lastName?: string;
  @text("phone") phone?: string;
  @text("phone_ext") phoneExt?: string;
  @text("email") email?: string;
  @text("address_line_1") addressLine1?: string;
  @text("address_line_2") addressLine2?: string;
  @text("city") city?: string;
  @text("state") state?: string;
  @text("zip") zip?: string;
  @text("notes") notes?: string;
  @readonly @date("created_at") createdAt?: number;
  @readonly @date("updated_at") updatedAt?: number;
}
