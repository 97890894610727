import { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link } from "react-router-dom";
import { List, ListItemText, Button, ListItemButton } from "@mui/material";
import { Add, Download } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { database } from "..";
import Tax from "../model/tax";

function TaxListPage() {
  const [taxesData, setTaxesData] = useState<Tax[]>([]);

  useEffect(() => {
    (async () => {
      const taxCollection = database.get<Tax>("taxes");
      let query = taxCollection.query();
      const taxes: Tax[] = await query.fetch();
      setTaxesData(taxes);
    })();
  }, []);

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.controlHeaderButtonContainer}>
              {/* <Button variant="outlined" startIcon={<Download />}>
                Export
              </Button> */}
              <Button
                component={Link}
                to="/taxes/details"
                color="success"
                variant="contained"
                startIcon={<Add />}
              >
                New Tax
              </Button>
            </div>
          </div>
          <List>
            {taxesData.map((tax) => (
              <ListItemButton
                component={Link}
                to={`/taxes/details?id=${tax.id}`}
                key={tax.id}
              >
                <ListItemText
                  primary={tax.title}
                  secondary={`${tax.percentRate}%`}
                />
              </ListItemButton>
            ))}
          </List>
        </main>
      </div>
    </div>
  );
}

export default TaxListPage;
