import { Model } from "@nozbe/watermelondb";
import { text, date, readonly, relation } from "@nozbe/watermelondb/decorators";
import Customer from "./customer";

export default class Photo extends Model {
  static table = "photos";

  @relation("customers", "customer_id") customer?: Customer;
  @text("photo_data") photoData?: string;
  @readonly @date("created_at") createdAt?: number;
  @readonly @date("updated_at") updatedAt?: number;
}
