import {
  Breadcrumbs,
  Button,
  Link as MUILink,
  Typography,
} from "@mui/material";
import TopNav from "../components/TopNav";
import { Link, useSearchParams } from "react-router-dom";
import { Image as KImage, Layer, Line, Stage } from "react-konva";
import { useEffect, useRef, useState } from "react";
import pageStyle from "./PhotoPage.module.css";
import { database } from "..";
import Photo from "../model/photo";
import Customer from "../model/customer";
function PhotosPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const cid = searchParams.get("cid");

  const [isModified, setIsModified] = useState(false);

  const [kImg, setKImg] = useState<any>(null);
  const imageObj = new Image();

  const loadImage = () => {
    if (!imageObj) return;
    setKImg(imageObj);
  };

  imageObj.onload = loadImage;
  imageObj.onchange = loadImage;

  useEffect(() => {
    if (!imageObj) return;
    (async () => {
      if (!id) {
        return;
      }
      const photo = await database.get<Photo>("photos").find(id);
      const file = photo.photoData;
      if (!file) return;
      imageObj.src = file;
      setLines([]);
      setSearchParams((prev) => {
        prev.set("cid", photo.customer?.id || "");
        return prev;
      });
    })();
  }, [id]);

  const [tool, setTool] = useState("pen");
  const [lines, setLines] = useState<{ tool: string; points: number[] }[]>([]);
  const isDrawing = useRef(false);
  const stageRef = useRef<any>(null);

  const handleMouseDown = (e: any) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e: any) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const canSave = () => {
    return isModified || lines.length != 0;
  };

  const uploadImage = (e: any) => {
    setIsModified(true);
    if (!imageObj) return;
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    imageObj.src = url;
    setLines([]);
  };

  const saveImage = async () => {
    const uri = stageRef.current.toDataURL();
    const cr = await database.get<Customer>("customers").find(cid || "");
    if (!id) {
      await database.write(async () => {
        const newPhoto = await database.get<Photo>("photos").create((p) => {
          const c: any = p.customer;
          c.set(cr);
          p.photoData = uri;
        });
        setSearchParams((prev) => {
          prev.set("id", newPhoto.id);
          return prev;
        });
      });
    } else {
      await database.write(async () => {
        const photo = await database.get<Photo>("photos").find(id);
        await photo.update((p) => {
          p.photoData = uri;
        });
      });
    }
    setLines([]);
    setIsModified(false);
  };

  return (
    <div className="page">
      <TopNav />
      <main className={pageStyle.main}>
        <Breadcrumbs>
          <MUILink
            component={Link}
            underline="hover"
            color="inherit"
            to="/customers"
          >
            Customers
          </MUILink>
          <MUILink
            component={Link}
            underline="hover"
            color="inherit"
            to={`/customers/details?id=${cid}`}
          >
            Details
          </MUILink>
          <Typography color="text.primary">Photo</Typography>
        </Breadcrumbs>
        <form>
          <input onChange={uploadImage} type="file" id="input" />
        </form>
        <div className={pageStyle.canvasContainerContainer}>
          <div id="canvasContainer" className={pageStyle.canvasContainer}>
            <Stage
              width={document.getElementById("canvasContainer")?.scrollWidth}
              height={document.getElementById("canvasContainer")?.scrollHeight}
              onMouseDown={handleMouseDown}
              onMousemove={handleMouseMove}
              onMouseup={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchMove={handleMouseMove}
              onTouchEnd={handleMouseUp}
              ref={stageRef}
            >
              <Layer>
                <KImage
                  image={kImg}
                  width={
                    document.getElementById("canvasContainer")?.scrollWidth
                  }
                  height={
                    document.getElementById("canvasContainer")?.scrollHeight
                  }
                />
                {lines.map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke="#df4b26"
                    strokeWidth={5}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation={
                      line.tool === "eraser" ? "destination-out" : "source-over"
                    }
                  />
                ))}
              </Layer>
            </Stage>
          </div>
        </div>
        <Button
          disabled={!canSave()}
          variant="contained"
          color="success"
          onClick={saveImage}
        >
          Save
        </Button>
      </main>
    </div>
  );
}

export default PhotosPage;
