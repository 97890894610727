import { Model } from "@nozbe/watermelondb";
import { text, date, readonly, field } from "@nozbe/watermelondb/decorators";

export default class Tax extends Model {
  static table = "taxes";

  @text("title") title?: string;
  @field("percent_rate") percentRate?: number;
  @readonly @date("created_at") createdAt?: number;
  @readonly @date("updated_at") updatedAt?: number;
}
